@import '../variables';


    .category-description h1 {
        font-family: $font;
        font-size: 2em;
        color: #bbb;
        font-weight: 100;
        display: inline;
    }

    span.category-description-second {
        font-family: $font;
        font-size: 24px;
        color: #666;
        font-weight: 700;
    }

    .catalog--header h1 {
       margin: 0;
    }

    .product--box--plan .no-link-box h3, .product--box--plan a h3 {
        display: block;
        font-size: 1.25em;
        font-weight: 600;
        min-height: 25px;
        color: #666;
    }
    .col {
        display: flex;
        flex-wrap: nowrap;
    }

    .PlanIncluded {
        color: #666666;
        font-size: 1rem;
        letter-spacing: -1px;
        font-family: $font-light;
    }
    .product--features-box {
        border-top: 1px solid #666666;
        border-left: 1px solid #666666;
        border-right: 1px solid #666666;
        border-bottom: 8px solid #0197a9;
        border-radius: 5px;
        text-align: center;

    }
    .plan-included {
        padding: 0 !important;
        justify-content: center !important;
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 2em;
    }
    .heigh-imagen {
        height: 50px;
    }
    // responsive plan included
    /*flex: 0 0 auto;
        width: 33.3333333333%;
        element.style {
    max-width: 50px;
    padding-left: 40%;
    padding-top: 1em;
    padding-bottom: 1.5em;



.ui--modal-notification {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.6);
    z-index: 9;
    opacity: 0;
    visibility: hidden;
}


}
        */

    section.p-0 {
        padding: 0!important;
    }

    section.mt-3 {
        margin-top: 1rem!important;
    }
    section.m-0 {
        margin: 0!important;
    }
    section.justify-content-center {
        justify-content: center!important;
    }
    section.row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    div.row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    img.style-image {
        width: 50px;
        height: 50px;
        margin: 5%;
    }
    .cross--module h2 {
        color: #666666;
        font-size: 1.5rem;
        letter-spacing: -1px;
        scroll-margin-top: 60px;
    }

    .plan-desc {
        font-size: 1.1rem !important;
        line-height: 1.5rem !important;
        color: #666666;
        width: 25em;
    }
    .plan-desc .plazo-cont {
        color: #666666;
    }

    .plazo-cont .mt-2 {
        margin-top: .5rem!important;
    }
    .plan-desc .row {
        --bs-gutter-x: 1.5rem !important;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
    }
    .plazo-cont .col-lg-4 {
        //flex: 0 0 50%;
        width: 33.3333333333%;
    }
    .plazo-cont .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .plan-desc .mt-2 {
        margin-top: .5rem!important;
    }
    .plazo-cont .col-lg-6 .form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem!important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529!important;
    background-color: #fff!important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da!important;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    }
    //-- header modal
    .modal-header .btn-closed {
        padding: .5rem!important;
        margin: -2.5rem -36.5rem -.5rem auto!important;
    }
    .btn-size {
        font-size: 45px!important;
        width: 0px;
        height: 15px;
        display: flex;
        align-items: flex-end;

    }

    //----- modal  boton
    .product--purchase section a {
        color: white !important;
        width: 100%;
        margin-top: .5em;
        //height: 50rem;
        font-size: 1.5em !important;
    }
    .product--modal {
        width: 40%;
        min-height: 505px;
    }
    /*.product--main {
        width: auto;
        max-width: 1060px;
        margin: 0 auto 0 15% !important;
        overflow: hidden;
    }*/
    .product--purchase section p {
        display: block;
        padding-left: 0.2rem !important;
    }
    .product--purchase section h2 {
        display: block;
        padding-left: 0.2rem !important;
    }
.product--description-line > p.plan-text {
    color: $red;
    b {
        cursor: pointer;
        text-decoration: underline;
    }
}
    .product--main .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
    }

   /* .ui--modal-notification>div>section {
        width: 800px;
        top: -120px !important;
        height: 180px;
    }*/
    .carousel-product .slick-slide {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .carousel-product img {
        width: 100%;
        height: auto;
        padding: 20%;
    }
    .cross--responsive{
        display:none !important;
    }
    // Elementos .. tu plan incluye
    .product--main .text-start.mt-5 {
        margin-top: 3rem !important;
    }
    .section .row {
        display: flex;
        flex-wrap: wrap;
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        justify-content: center !important;
        width: auto;
    }

    @media screen and (max-width: 991px) {
        .cross--responsive {
            display: flex !important;
            width: auto;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 0;
            flex-wrap: wrap;
        }
        .carousel-product img {
            padding: 25%;
        }
        .ui--modal-notification {
            position: absolute;
        }
        /*
        .modal-header .btn-closed {
            margin: -9.5rem -15.5rem -.5rem auto!important;
        }
        .buttom-close {
            width: 100% !important;
        }
        .buttom-purchases {
            width: 100% !important;
        }*/

    }
    @media  (max-width: 760px){
        .ui--modal-notification {

            position: absolute;
            top: -11rem;
        }
        .modal-header .btn-closed {
            margin: -2% -97% auto auto!important ;/// -9.5rem -15.5rem -.5rem auto!important;
        }
        .buttom-close {
            width: 100% !important;
        }
        .buttom-purchases {
            width: 100% !important;
        }
    }

    @media  (max-width: 560px){
        .ui--modal-notification {

            position: absolute;
            top: -11rem;
        }
        .modal-header .btn-closed {
            margin: -2% -97% auto auto!important ;/// -9.5rem -15.5rem -.5rem auto!important;
        }
        .buttom-close {
            width: 100% !important;
        }
        .buttom-purchases {
            width: 100% !important;
        }
        .ui--modal-notification>div>section {
            top: 0;
            max-height: 90vh;
            overflow: auto;
        }
        .btn-Mpostpago  {
            padding-bottom: 0 !important;
        }
    }
    @media  (max-width: 460px){
        .ui--modal-notification {

            position: fixed;
            top:0;
            overflow-x: auto;
        }
        .modal-header .btn-closed {
            margin: -2% -97% auto auto!important ;/// -9.5rem -15.5rem -.5rem auto!important;
        }
        .buttom-close {
            width: 100% !important;
        }
        .buttom-purchases, .btn-modal {
            width: 100% !important;
        }

    }

    @media (min-width:1024px){
        .ui--modal-notification>div>section {
        width: 57%;
    }}
