@import '../variables';
.renewal {
  color: #d52b1e; background-color: white;
  .roboto-bold { font-family: $font-bold; }

  .red-color { color: #d52b1e; }

  .black-color { color: $black; }

  .text-bold { font-weight: 700; }

  .form-cont,
  .ui--modal-notification.active section {
    background: white;
    padding: 4%;
    border-radius: 14px;
    box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.29);
    -webkit-box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.16);
    -moz-box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.16);
  }
  .ui--modal-notifications.active section {
    background: white;
    padding: 4%;
    border-radius: 14px;
    box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.29);
    -webkit-box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.16);
    -moz-box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.16);

  }
  .ui--notification{
    border: none;
    margin: 0 0 1rem;
    padding: 0;
    &::before{
      content: '';
    }
    p{
      &::before{
        content: '';
      }
    }
  }
  .form-bottom-text { color: #888888; font-weight: 300; }

  .form-control {
    border: 1px solid #DEDEDE;
    &.error { border-color: #d52b1e; }
  }

  .btn-close {
    position: absolute;
    right: -12px;
    top: -13px;
    background: white;
    border-radius: 24px;
    width: 32px;
    height: 32px;
  }
  .contPrincipal{
    margin-top: calc(22% - 200px);
    .titleForm1{
      color: #3c3c3c;
      font-weight: bold;
    }
    .textForm1{
      color: #3c3c3c;
      font-size: 1.3rem;
      width: 60%;
      margin: 0 0 2rem;
    }
    .buttonForm1{
      width: 40%;
      height: 40px !important;
      line-height: 20px !important;
    }
    .icoHoja{
      width: 40px;
      margin: 0 auto 2rem;
    }
  }
}

.ModalRenewal{
  h1{
    color: #3c3c3c;
    font-size: 1.6rem;
    font-weight: bold;
  }
  p{
    color: #3c3c3c;
    font-weight: normal;
    font-size: 1.2rem;
    margin: 2rem 0;
    line-height: 1.5;
  }
  img{
    width: 50px;
    margin: 0 auto 1rem;
  }
  .botonModal1{
    margin-bottom: 0 !important;

  }
}

.modalToken{
  h2{
    font-weight: bold;
  }
  header{
    margin-bottom: 0;
    p{
      width: 92%;
      margin: 0 auto;
      font-size: 1.6rem !important;
    }
  }

  .inputCode{
    width: 298px;
    margin: 0 auto;
  }

  .inputTokenValido{
    margin: 0 auto 0.2rem !important;
  }
  .FirstBtn{
    width: 298px;
    margin-left: calc(50% - 149px) !important;
    margin-top: 1rem;
  }
  .secondBtn{
    background-color: transparent;
    color: $blue;
    span{
      text-decoration: underline;
    }
  }
}

.ModalRenewal3{
  .title{
    font-size: 1.6rem;
    color: #3c3c3c;
    font-weight: bold;
  }
  img{
    width: 50px;
    margin: 0 auto 1rem;
  }
  .text{
    font-size: 1rem;
    color: #3c3c3c;
    font-weight: normal;
    margin-bottom: 2rem;
  }
  .textoModalR3{
    color: #3c3c3c !important;
  }
  br{
    display: none !important;
  }
  span{
    font-size: 1rem !important;
    font-weight: normal !important;
  }
  .black-color{
    display: block !important;
    font-size: 1.6rem !important;
    font-weight: bold !important;
  }
  .botonModal3{
    width: 298px;
    margin-left: calc(50% - 149px) !important;
    margin-top: 1rem;
    display: block;
  }
}


@media (max-width: 640px){
  .contPrincipal{
    margin-top: 6rem !important;
    .textForm1{
      width: 90% !important;
      font-size: 1.1rem !important;
    }
    .buttonForm1{
      width: 100% !important;
    }
  }

  .modalToken header p{
    font-size: 1rem !important;
  }
  .modalToken .secondBtn span {
    font-size: 1rem;
  }
  .ModalRenewal .botonModal1{
    height: 40px !important;
    line-height: 20px;
    font-size: 1rem;
  }
  .has-bootstrap .my-5{
    margin: 0 !important;
  }
  .ModalRenewal h1{
    font-size: 1.2rem;
  }
  .ModalRenewal p{
    margin: 1rem 0;
    font-size: 1rem;
  }
  .ModalRenewal3 .black-color{
    font-size: 1.2rem !important;
    margin-bottom: 1rem;
  }
  .btnCall{
    width: 100% !important;
  }
}

.modal-availability {
  .ui--modal-notification section {
    padding: 30px 40px 40px 40px !important;
    border-radius: 16px;
    max-width: 600px;
  }

  .ui--modal-notification section .ModalRenewal {
    text-align: center;
  }

  .ui--modal-notification section .ModalRenewal nav {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .ui--modal-notification section .ModalRenewal nav button span i {
    color: #6d6d6d;
    font-size: 1.2em;
  }

  .ui--modal-notification section .ModalRenewal h1 {
    text-align: center;
    color: #da291c;
  }

  .ui--modal-notification section .ModalRenewal p {
    text-align: center;
    width: 100%;
    margin: 20px auto;
    display: block;
    font-size: 1.1em;
    color: #333;
  }

  .ui--modal-notification section .ModalRenewal form {
    display: flex;
    flex-flow: column;
    gap: 10px;
    align-items: center;
  }

  .ui--modal-notification section .ModalRenewal form > div {
    width: 100%;
  }

  .ui--modal-notification section .ModalRenewal form input {
    border-radius: 5px;
    border-color: #bbb;
  }

  .ui--modal-notification section .ModalRenewal > button, .ui--modal-notification section .ModalRenewal form button {
    border-radius: 5px;
    background: #da291c;
    color: #fff;
    width: 230px;
    height: 40px;
    margin: 10px 0 0;
  }
}

section.product--purchase .no-availability {
  width: 40%;
}

.product--purchase > p, .product--purchase .no-availability p {
  width: 100%;
  margin: 0 0 20px;
}

@media (max-width: 640px){
  section.product--purchase .no-availability {
    padding-top: 14px;
    width: 100% !important;
  }
  .message-movil{
    font-size: 13px;
    text-align: justify;
  }  
}
