.simselection {
  margin-bottom: 32px;

  &--title {
    color: #0097a9;
    font-weight: 500;
  }

  &--options {
    width: 100%;
    max-width: 200px;
    margin: 1em 0 1.5em 0;

    .terminos {
      margin: 15px 0 25px 0;

      label {
        width: 100%;
        color: #666666;
      }

      a {
        color: #0097a9;
      }

      label.error {
        float: left
      }
    }

    label {
      width: 50%;

      span {
        font-size: 0.9em;
        font-weight: 400;
        color: #666666;
      }

      input {
        display: inline-block;
        width: auto;
        margin-right: 6px;
        font-size: 1em;

        &[type="radio"] {
          -webkit-appearance: radio;
          -moz-appearance: radio;
          appearance: radio;
        }

        &[type="checkbox"] {
          -webkit-appearance: checkbox;
          -moz-appearance: checkbox;
          appearance: checkbox;
        }
      }
    }
  }

  .grid {
    display: flex;

    .icon-container {
      width: 20px;
      padding-top: 2px;
    }

    .icon-info {
      width: 12px;
      height: 12px;
      border-radius: 200px;
      border: 1.6px solid #0097a9;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0097a9;
      font-size: 10px;
      font-weight: 700;
    }

    .text {
      color: #7d7d7d;
      font-size: 0.85em;
      font-weight: 400;

      .link {
        color: #0097a9;
        padding-left: 4px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }
}

@media (max-width: 767px) {
  .simselection {
    margin-bottom: 32px;
  
    &--title {
      color: #0097a9;
      font-weight: 500;
    }
  
    &--options {
      width: 100%;
      width: 200px;
      margin: 1em 0 1.5em 0;
  
      .terminos {
        margin: 15px 0 25px 0;
  
        label {
          width: 100%;
          color: #666666;
        }
  
        a {
          color: #0097a9;
        }
  
        label.error {
          float: left
        }
      }
  
      label {
        width: 50%;
  
        span {
          font-size: 0.9em;
          font-weight: 400;
          color: #666666;
        }
  
        input {
          display: inline-block;
          width: auto;
          margin-right: 6px;
          font-size: 1em;
  
          &[type="radio"] {
            -webkit-appearance: radio;
            -moz-appearance: radio;
            appearance: radio;
          }
  
          &[type="checkbox"] {
            -webkit-appearance: checkbox;
            -moz-appearance: checkbox;
            appearance: checkbox;
          }
        }
      }
    }
  
    .grid {
      display: flex;
  
      .icon-container {
        width: 20px;
        padding-top: 2px;
      }
  
      .icon-info {
        width: 12px;
        height: 12px;
        border-radius: 200px;
        border: 1.6px solid #0097a9;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0097a9;
        font-size: 10px;
        font-weight: 700;
      }
  
      .text {
        color: #7d7d7d;
        font-size: 0.78em;
        font-weight: 400;
  
        .link {
          color: #0097a9;
          padding-left: 4px;
          text-decoration: underline;
        }
      }
  
    }
  }
  
}