@import "../variables";

.esimModal {



  &--esim {
    position: fixed;
    background-color: rgba(black, 0.6);
    z-index: 99;
    opacity: 0;
    opacity: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    animation: modal .5s ease-in;
    transition: .50s;

    .tabSlider {
      width: 85%;
      margin: 0 auto;
      margin-bottom: 1.5em;
      position: relative;
      padding: 0 32px 0 32px;
      box-sizing: border-box;

      .slick-slider {
        // display: flex;
        position: initial;
      }

      .stmElementHome {
        font-size: 1rem;
        height: auto;
        font-weight: 400;
        text-align: center;
        position: relative;
        padding: 10px 12px 18px;
        cursor: pointer;
        color: #8f8c8c;
        white-space: nowrap;
        box-sizing: border-box;

        &::after {
          transition: all 0.5s ease;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          display: block;
          background-color: #bbb;
        }

        &.active {
          color: #3c3c3c;
          font-weight: bold;

          &::after {
            height: 8px;
            background-color: $red;
          }
        }
      }

      .slick-prev {
        z-index: 2;
        height: 100%;
        height: max-content;
        position: absolute;
        left: 5px;
        top: 50%;

        &::before {
          content: "";
          z-index: 3;
          display: inline-block;
          border-top: 3px solid #b4b6b7;
          border-left: 3px solid #b4b6b7;
          width: 10px;
          height: 10px;
          transform: rotate(-45deg);
        }

        svg {
          display: none;
        }
      }

      .slick-next {
        z-index: 2;
        height: 100%;
        height: max-content;
        position: absolute;
        right: 8px;
        top: 50%;

        &::before {
          content: "";
          z-index: 3;
          display: inline-block;
          border-right: 3px solid #b4b6b7;
          border-bottom: 3px solid #b4b6b7;
          width: 10px;
          height: 10px;
          transform: rotate(-45deg);
        }

        svg {
          display: none;
        }
      }
    }

    >div {
      width: 100vw;
      >section {
        width: 90%;
        background-color: white;
        position: relative;
        padding: 40px !important;
        border-radius: 14px;
        max-width: 1024px;
        margin: 10vh auto 0 auto;


        header {

          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 2.5rem;

          i {
            display: inline-block;
            width: 6rem;
            width: max-content;
            margin: 0 0 0.5rem 0;
            font-size: 24px;

            svg {
              width: 100%;
              height: auto;
              max-height: 3rem;
              display: block;
            }
          }

          h1 {
            font-size: 2rem;
            text-align: center;
            margin-bottom: 0.5em;

          }

          p {
            font-weight: 400;
          }
        }

        a.close {
          position: absolute;
          top: 1rem;
          right: 1rem;
          z-index: 1;

          svg {
            display: inline-block;
            width: 1.4rem;
          }
        }
      }
    }
  }

  &--terminals {

    position: relative;
    width: 100%;
    padding: 40px;
    margin: 0 auto;
    box-sizing: border-box;

    &--forcecenter {
      > .slick-list .slick-track {
        display: flex;
        justify-content: center;
      }
    }

    &--terminal {
      &--title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: center;
        min-height: 60px;
      }

      picture {
        box-sizing: border-box;
        overflow: hidden;

        img {
          height: 200px;
          width: auto;
          width: auto;
          object-fit: cover;
          margin: 0 auto;

        }
      }
    }

    .slick-slider {
      // display: flex;
      position: initial;

      .slick-track {
        // width: 100% !important;
      }

      .slick-prev {
        z-index: 2;
        height: 100%;
        border-radius: 300px;
        padding: 12px;
        width: 24px;
        height: max-content;
        position: absolute;
        left: 0;
        top: 50%;

        &::before {
          content: "";
          z-index: 3;
          position: absolute;
          top: calc(50% - 28px);
          display: inline-block;
          border-top: 3px solid $dark;
          border-left: 3px solid $dark;
          width: 32px;
          height: 32px;
          left: 0;
          transform: rotate(-45deg);


        }

        svg {
          display: none;
        }
      }

      .slick-next {
        z-index: 2;
        height: 100%;
        border-radius: 300px;
        padding: 12px;
        height: max-content;
        position: absolute;
        right: 0;
        top: 50%;

        &::before {
          content: "";
          z-index: 3;
          display: inline-block;
          position: absolute;
          top: calc(50% - 28px);
          right: 10px;
          border-right: 3px solid $dark;
          border-bottom: 3px solid $dark;
          width: 32px;
          height: 32px;
          position: absolute;
          transform: rotate(-45deg);
        }

        svg {
          display: none;
        }
      }

      .slick-dots {
        margin: 0 auto;
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;

        li button {
          font-size: 0;
          width: 10px !important;
          height: 10px !important;
          border-radius: 50%;
          background: $gray;
          margin: 4px !important;

        }

        .slick-active button {
          background: #ffd964 !important;
        }

      }
    }



    .slick-slide {
      padding: 0 12px;
      box-sizing: border-box;

      >div {
        // width: 260px;
        width: 100%;
        // min-height: 420px;
        background: white;
        box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.15);
        margin: 12px 0;
        border-radius: 8px;
        padding: 24px 32px;
        box-sizing: border-box;
      }

    }

  }


}

@keyframes modal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@media screen and (max-width: 1440px) {



  .esimModal {

    &--esim {

      >div {
        width: 100vw;
        >section {
          padding: 12px 0 !important;


          header {
            width: 100%;

            margin-bottom: 1.5rem;



            i {
              display: inline-block;
              width: 6rem;
              width: max-content;
              margin: 0 0 0.5rem 0;

              svg {
                width: 100%;
                height: auto;
                max-height: 3rem;
                display: block;
              }
            }

            h1 {
              font-size: 1.5rem;

            }

          }

          a.close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            z-index: 1;

            svg {
              display: inline-block;
              width: 1.4rem;
            }
          }
        }
      }
    }

    &--terminals {

      .slick-slider {
        .slick-prev {
          left: 10px;
  
          &::before {
            left: 10px;
          }
  
        }
  
        .slick-next {
          right: 10px;
  
          &::before {
            right: 10px;
          }
  
        }
  
      }
    }

  }

}

@media screen and (max-width: 1024px) {



  .esimModal {

    &--esim {

      >div {
        width: 100vw;
        >section {
          width: 90vw;
          background-color: white;
          position: relative;
          padding: 12px !important;
          border-radius: 14px;
          max-width: 1024px;
          // margin: 10vh 10px 0 10px;


          header {
            width: 100%;

            margin-bottom: 1.5rem;



            i {
              display: inline-block;
              width: 6rem;
              width: max-content;
              margin: 0 0 0.5rem 0;

              svg {
                width: 100%;
                height: auto;
                max-height: 3rem;
                display: block;
              }
            }

            h1 {
              font-size: 1.5rem;

            }

          }

          a.close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            z-index: 1;

            svg {
              display: inline-block;
              width: 1.4rem;
            }
          }
        }
      }
    }

    &--terminals {

      position: relative;

      &--terminal {
        &--title {
          font-size: 1rem;
          margin-bottom: 1rem;
          text-align: center;
          min-height: 40px;
        }

        picture {
          padding: 0;
          box-sizing: border-box;
          height: 100%;
          overflow: hidden;

          img {
            height: 200px;
            width: auto;
            object-fit: cover;
            margin: 0 auto;
          }
        }
      }

      .slick-slide {
        // padding: 0 0px;

        >div {
          // min-width: 120px;
          height: 290px;
          background: white;
          box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.15);
          margin: 12px 0;
          border-radius: 8px;
          padding: 18px 12px;
        }

      }

    }


  }

}

@media screen and (max-width: 768px) {



  .esimModal {

    &--esim {

      >div {

        >section {
          width: calc(100% - 20px);
          margin: 10vh 10px 0 10px;
          box-sizing: border-box;


          header {
            width: 100%;

            margin-bottom: 1.5rem;



            i {
              display: inline-block;
              width: 6rem;
              width: max-content;
              margin: 0 0 0.5rem 0;

              svg {
                width: 100%;
                height: auto;
                max-height: 3rem;
                display: block;
              }
            }

            h1 {
              font-size: 1.25rem;
            }

            p {
              font-size: 14px;
            }

          }

          a.close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            z-index: 1;

            svg {
              display: inline-block;
              width: 1.4rem;
            }
          }
        }
      }
    }

    &--terminals {

      position: relative;
      width: 100%;
      padding: 0;

      &--terminal {
        &--title {
          font-size: 1rem;
          margin-bottom: 1rem;
          text-align: center;
          min-height: 40px;
        }

        picture {
          padding: 0;
          box-sizing: border-box;
          height: 100%;

          img {
            height: 200px;
            width: auto;
            object-fit: cover;
            margin: 0 auto;
          }
        }
      }

      .slick-slide {
        // padding: 0 0px;

        >div {
          // min-width: 120px;
          height: 290px;
          background: white;
          box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.15);
          margin: 12px 0;
          border-radius: 8px;
          padding: 18px 12px;
        }

      }

    }


  }

}