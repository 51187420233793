@import '../variables';
@import '../settings';
@import '../base/common';

.search {

  &--box {width: 100%; position: relative; width: 100%; display: inline-block;
    @include phone {  width: 83%!important; display: block; margin: 0 auto; }
    label {width: 100%; background-color: white;
      input {border: solid; border-width: 1px; border-color: $gray; font-family: $icon, $font;
        margin-left: 1em; border-radius: 5px; height: 3em; font-size: 16px;
        @include phone { margin-left: 0em; }
        // &:focus {border-color: $red;}
      }
    }
    &-reset {position: absolute; top: 0.5em; right: 2.5em; font-size: 1.1em; color: $blue; display: none;
      svg {height: 16px; display: block;}
      // &::after {content: "\f00d"; font-family: $icon;}
    }
    button, a.DEMO {background-color: $gray; color:white; position: absolute; top: 0; bottom: 0; right: 0; font-size: 1.1em;
      padding-left: 1em; padding-right: 1em; border-radius: 5px;
      @include phone { background-color: #bbbbbb; color: #fff; position: absolute; top: 0; bottom: 0; right: 0; font-size: 1.1em; padding-left: 1em; padding-right: 1em; border-radius: 5px;}
      span {@include padbox(0.5em); color: white;}
    }

    &-results {
      position: absolute; top: 100%; left: 0; right: 0; width: auto; z-index: 10;
      @include tablet {
        left: 1em;
      }
      a { width: 100%; @include padblock(0.5rem); background-color: $soft;
        &:hover, &:focus { background-color: $light; }
      };
      li.selected a { background-color: $light; };
    }
  }

  &--notfound { width: 100%; font-size: 1rem;
    @include tablet { font-size: 1.2rem; }
    p { margin: 1rem 0;
      &::before { content: "\f010"; font-family: $icon; color: $red; display: inline-block; padding-right: 0.5rem; }
      b {
        &::before, &::after { content: "''"; }
      }
    }
    ul { width: 100%; @include padblock(1rem); background-color: $soft;
      li { padding: 0.25rem 0;
        span {
          &::before { content: "· "; color: red; }
        }
      }
    }
  }

  &--grid {width: 100%; @include module;
    @include tablet {width: 100%; @include flexbox; @include flow-rw; @include just-between; @include items-stretch;}
    @include desk {width: 100%;}
    article {width: 100%;
      @include tablet {width: 31%;}
      @include desk {width: 24%;}
      a.max95 {
        max-height: none;
      }
    }
  }

}
