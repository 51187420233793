.plan-fijo-pop.swal2-popup {
  padding-bottom: 0;
  width: auto;
}

.plan-fijo-container.swal2-html-container {
  margin: 0;
  text-align: left;
  min-width: 800px;
  max-width: 100%;
  font-size: 1rem;
  line-height: 1.5;

  &:has(.paso3) {
    min-width: 600px;
  }

  span {
    display: inline;
  }
}

.has-bootstrap.modal-planes-fijos {

  /* MODAL */
  .modal-title {
    background: #0197a9;
  }

  .modal-body {
    padding: 0 1rem 1rem 1rem;
  }

  .modal-body img {
    width: 40%;
    max-width: 231px;
  }

  @media screen and (max-width: 575px) {
    .modal-body img {
      width: 50%;
    }
  }

  .modal-close {
    font-size: 2rem;
  }


  .modal-footer h1 {
    line-height: 1.5rem;
  }

  .modal-footer .price-cont {
    color: #666666;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 0.9rem;
    font-family: $font-medium;
    margin: 0 5%;
    letter-spacing: -1px;
    width: 90%;
  }

  .modal-footer .price-cont .decimal {
    font-size: 2.3rem;
  }

  .modal-footer .mobile-price {
    font-family: $font-medium;
    color: #da291c;
    font-size: 2.5rem;
    margin-top: 0;
    /* letter-spacing: -2px; */
  }

  .modal-footer .quetzal {
    font-size: 2rem;
  }

  .modal-footer .mobile-price .mobile-price-text {
    font-size: 1.5rem;
    font-family: $font-medium;
  }
}
